import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import VideoFileContext from 'App/VideoFileContext';
import { scaleLinear } from '@visx/scale';
import { localPoint } from '@visx/event';
import VideoStateContext from 'App/VideoStateContext';
import EditorPanelContent from 'App/EditorPanel/EditorPanelContent';
import VideoSeekContext from 'App/VideoSeekContext';

const ZOOM_IN_FACTOR = 1.1;
const ZOOM_OUT_FACTOR = 1 / ZOOM_IN_FACTOR;

interface Props {
    width: number;
    height: number;
}

const EditorPanelSVG: React.FunctionComponent<Props> = ({ width, height }: Props) => {
    const { videoFileInfos } = useContext(VideoFileContext);
    const { playbackPosition } = useContext(VideoStateContext);
    const { seekTo } = useContext(VideoSeekContext);

    const [editingRegion, setEditingRegion] = React.useState<{ from: number; to: number }>({ from: 0, to: 0 });
    const [dragXStart, setDragXStart] = React.useState<number | null>(null);
    const didDrag = React.useRef<boolean>(false);

    const maxVideoDuration = useMemo(
        () => Math.max(...videoFileInfos.map((videoFileInfo) => videoFileInfo.duration), 0),
        [videoFileInfos]
    );

    useEffect(() => {
        setEditingRegion({
            from: 0,
            to: maxVideoDuration,
        });
    }, [maxVideoDuration]);

    const timeScale = scaleLinear<number>().domain([editingRegion.from, editingRegion.to]).range([0, width]);

    const onSvgClick = useCallback(
        (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
            if (didDrag.current) {
                return;
            }

            const posOnSvg = localPoint(event) ?? { x: 0, y: 0 };
            const playPosition = timeScale.invert(posOnSvg.x);
            seekTo(playPosition);
        },
        [timeScale, seekTo]
    );

    const onSvgScroll = useCallback(
        (event: React.WheelEvent<SVGSVGElement>) => {
            const currentInterval = editingRegion.to - editingRegion.from;
            const zoomFactor = event.deltaY > 0 ? ZOOM_IN_FACTOR : ZOOM_OUT_FACTOR;

            const zoomScale = scaleLinear<number>()
                .domain([0, currentInterval])
                .range([0, currentInterval * zoomFactor]);

            // Scale editing region by zoom factor around the current position.
            const newEditingRegion = {
                from: zoomScale(editingRegion.from - playbackPosition) + playbackPosition,
                to: zoomScale(editingRegion.to - playbackPosition) + playbackPosition,
            };

            setEditingRegion(newEditingRegion);
        },
        [editingRegion.from, editingRegion.to, playbackPosition]
    );

    const onSvgMouseDown = useCallback(
        (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
            const posOnSvg = localPoint(event) ?? { x: 0, y: 0 };
            didDrag.current = false;
            setDragXStart(posOnSvg.x);
        },
        [setDragXStart]
    );

    const onSvgMouseMove = useCallback(
        (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
            if (dragXStart === null) {
                return;
            }

            const posOnSvg = localPoint(event) ?? { x: 0, y: 0 };
            const dragXEnd = posOnSvg.x;

            if (Math.abs(dragXEnd - dragXStart) > 0) {
                didDrag.current = true;
            }

            const dragTimeDelta = timeScale.invert(dragXStart) - timeScale.invert(dragXEnd);

            const newEditingRegion = {
                from: editingRegion.from + dragTimeDelta,
                to: editingRegion.to + dragTimeDelta,
            };

            setEditingRegion(newEditingRegion);
            setDragXStart(dragXEnd);
        },
        [dragXStart, timeScale, editingRegion.from, editingRegion.to]
    );

    const onSvgMouseUp = useCallback(
        (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
            setDragXStart(null);
        },
        [setDragXStart]
    );

    return (
        <svg
            width={width}
            height={height}
            style={{ backgroundColor: '#e0e0e0' }}
            onClick={onSvgClick}
            onWheel={onSvgScroll}
            onMouseDown={onSvgMouseDown}
            onMouseMove={onSvgMouseMove}
            onMouseUp={onSvgMouseUp}
            className={'no-select'}
        >
            <EditorPanelContent
                timeScale={timeScale}
                width={width}
                height={height}
                maxVideoDuration={maxVideoDuration}
            />
        </svg>
    );
};

export default EditorPanelSVG;
