import React, { useCallback, useContext } from 'react';
import { EatEventWithId } from 'types/EatEvent';
import { ScaleLinear } from 'd3-scale';
import DragHandle, { DragData } from 'App/EditorPanel/Event/DragHandle';
import { Group } from '@visx/group';
import EventStreamContext from 'App/EventStreamContext';

interface Props {
    eatEvent: EatEventWithId;
    timeScale: ScaleLinear<number, number>;
    y: number;
    height: number;
}

const MIN_EVENT_DURATION = 1.0;

const EventComponent: React.FunctionComponent<Props> = ({ eatEvent, timeScale, y, height }: Props) => {
    const { updateEatEvent } = useContext(EventStreamContext);

    const x = timeScale(eatEvent.time_start);
    const width = timeScale(eatEvent.time_end) - timeScale(eatEvent.time_start);

    const onStartHandleDragHandler = useCallback(
        ({ endX }: DragData) => {
            const newStartTime = timeScale.invert(endX);

            if (newStartTime < eatEvent.time_end - MIN_EVENT_DURATION) {
                updateEatEvent(eatEvent.id, {
                    time_start: newStartTime,
                });
            }
        },
        [eatEvent.id, eatEvent.time_end, timeScale, updateEatEvent]
    );

    const onEndHandleDragHandler = useCallback(
        ({ endX }: DragData) => {
            const newEndTime = timeScale.invert(endX);

            if (newEndTime > eatEvent.time_start + MIN_EVENT_DURATION) {
                updateEatEvent(eatEvent.id, {
                    time_end: newEndTime,
                });
            }
        },
        [eatEvent.id, eatEvent.time_start, timeScale, updateEatEvent]
    );

    return (
        <Group>
            <rect fill={'dodgerblue'} stroke={'white'} x={x} width={width} y={y} height={height} rx={5} />
            <DragHandle onDrag={onStartHandleDragHandler} x={x + 4} y={y + 4} width={2} height={height - 8} />
            <DragHandle onDrag={onEndHandleDragHandler} x={x + width - 6} y={y + 4} width={2} height={height - 8} />
        </Group>
    );
};

export default EventComponent;
