import React, { Fragment, useCallback, useContext } from 'react';
import VideoFileContext from 'App/VideoFileContext';
import { Button, Divider, Grid, List, ListItem } from '@material-ui/core';
import { getAudioWaveformData, prepareVideoFileInfo } from 'lib/video-analysis';
import { videoFileFormatter } from 'lib/string-format';
import FileDeleteButton from 'App/FileManager/FileDeleteButton';
import { HiddenInput } from 'App/HiddenInput';

interface Props {}

const FileManager: React.FunctionComponent<Props> = ({}: Props) => {
    const { addVideoFileInfo, updateVideoFileInfo, videoFileInfos } = useContext(VideoFileContext);

    const handleVideoUpload = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            if (event.target.files != null) {
                Array.from(event.target.files).forEach((file: File) => {
                    // We first want to get the general video information.
                    prepareVideoFileInfo(file)
                        .then((videoInfo) => {
                            return addVideoFileInfo(videoInfo);
                        })
                        .then((videoId) => {
                            // Then we want to get the audio waveform data.
                            getAudioWaveformData(file).then((audioWaveformData) => {
                                updateVideoFileInfo(videoId, {
                                    waveform: audioWaveformData,
                                });
                            });
                        });
                });
            }
        },
        [addVideoFileInfo, updateVideoFileInfo]
    );

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <List style={{ maxWidth: '100%', overflowX: 'auto' }}>
                    {videoFileInfos.map((videoFileInfo, idx) => (
                        <Fragment key={videoFileInfo.id}>
                            <ListItem>
                                {videoFileFormatter(videoFileInfo)}
                                &nbsp;
                                <FileDeleteButton fileId={videoFileInfo.id} />
                            </ListItem>
                            {idx < videoFileInfos.length - 1 && <Divider />}
                        </Fragment>
                    ))}
                </List>
            </Grid>
            <Grid item xs={12}>
                <HiddenInput
                    type="file"
                    multiple={true}
                    accept={'video/*'}
                    onChange={handleVideoUpload}
                    id="video-upload"
                />
                <label htmlFor="video-upload">
                    <Button variant="contained" component="span">
                        Load Video Files
                    </Button>
                </label>
            </Grid>
        </Grid>
    );
};

export default FileManager;
