import React from 'react';
import { VideoFileInfo, VideoFileInfoWithId } from 'types/VideoFileInfo';

interface IVideoFileContext {
    videoFileInfos: VideoFileInfoWithId[];
    addVideoFileInfo: (fileOrFiles: VideoFileInfo) => string;
    updateVideoFileInfo: (videoFileId: string, updates: Partial<VideoFileInfo>) => void;
    deleteVideoFileInfo: (videoFileId: string) => void;
}

const defaultContext: IVideoFileContext = {
    videoFileInfos: [],
    addVideoFileInfo: () => {
        // Initial value. Replaced by context provider.
        return '';
    },
    updateVideoFileInfo: () => {
        // Initial value. Replaced by context provider.
    },
    deleteVideoFileInfo: () => {
        // Initial value. Replaced by context provider.
    },
};

const VideoFileContext = React.createContext(defaultContext);

export default VideoFileContext;
