import React, { useCallback, useContext, useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import VideoStateContext from 'App/VideoStateContext';
import { VideoFileInfo } from 'types/VideoFileInfo';
import { IconButton } from '@material-ui/core';
import { MdVolumeOff, MdVolumeUp } from 'react-icons/md';
import styled from 'styled-components';
import VideoSeekContext from 'App/VideoSeekContext';

const PlayerWrapper = styled.div`
    position: relative;
`;

const MuteButton = styled(IconButton)`
    position: absolute;
    top: 10px;
    right: 10px;
`;

interface Props {
    videoFileInfo: VideoFileInfo;
    isControllingInstance: boolean;
}

const VideoPlayerInstance: React.FunctionComponent<Props> = ({
    videoFileInfo,
    isControllingInstance = false,
}: Props) => {
    const { isPlaying, playbackPosition, setPlaybackPosition } = useContext(VideoStateContext);
    const { seekPosition } = useContext(VideoSeekContext);
    const [playerRef, setPlayerRef] = useState<ReactPlayer | null>();
    const [muted, setMuted] = useState<boolean>(false);

    useEffect(() => {
        if (isControllingInstance) {
            const interval = setInterval(() => {
                setPlaybackPosition(playerRef?.getCurrentTime() || 0);
            }, 100);

            return () => clearInterval(interval);
        }
    }, [isControllingInstance, isPlaying, playerRef, setPlaybackPosition]);

    useEffect(() => {
        playerRef?.seekTo(seekPosition, 'seconds');
    }, [playerRef, seekPosition]);

    const onMuteClickHandler = useCallback((e: React.MouseEvent) => {
        setMuted((prevState) => !prevState);
    }, []);

    return (
        <PlayerWrapper>
            <ReactPlayer
                ref={(r) => setPlayerRef(r)}
                url={videoFileInfo.objectURL}
                playing={isPlaying && playbackPosition < videoFileInfo.duration}
                pip={false}
                controls={false}
                muted={muted}
                width="100%"
                height="100%"
            />
            <MuteButton size="small" onClick={onMuteClickHandler}>
                {muted ? <MdVolumeOff /> : <MdVolumeUp />}
            </MuteButton>
        </PlayerWrapper>
    );
};

export default VideoPlayerInstance;
