import React, { useContext } from 'react';
import { ParentSize } from '@visx/responsive';
import EditorPanelSVG from 'App/EditorPanel/EditorPanelSVG';
import { MdFileDownload, MdFileUpload } from 'react-icons/md';
import { Grid, IconButton } from '@material-ui/core';
import { eatEventListDecoder } from 'lib/json-decoders';
import { HiddenInput } from 'App/HiddenInput';
import EventStreamContext from 'App/EventStreamContext';
import { downloadFile, splitFileName } from 'lib/files';
import moment from 'moment';

interface Props {}

const EditorPanel: React.FunctionComponent<Props> = (props: Props) => {
    const { setEatEvents, eatEvents } = useContext(EventStreamContext);
    const [prevVideoFileName, setPrevVideoFileName] = React.useState<string>('events.json');

    const handleEventJSONDownload = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        const eatEventsSorted = eatEvents.sort((a, b) => a.time_start - b.time_start);

        downloadFile({
            data: JSON.stringify(eatEventsSorted, null, 2),
            fileName: splitFileName(prevVideoFileName)[0] + '_' + moment().format('YYYY-MM-DD_HH-m-s') + '.json',
            fileType: 'text/json',
        });
    };

    const handleEventJSONUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files !== null && event.target.files.length === 1) {
            const file = event.target.files[0];
            const objectURL = URL.createObjectURL(file);

            fetch(objectURL)
                .then((response) => {
                    window.URL.revokeObjectURL(objectURL);
                    return response.json();
                })
                .then((jsonData) => {
                    setPrevVideoFileName(file.name);
                    eatEventListDecoder
                        .decodeToPromise(jsonData)
                        .then((eatEventList) => setEatEvents(eatEventList))
                        .catch((e) => console.error(`Could not decode eat events.\n${e}`));
                });
        }
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={1}>
                <HiddenInput
                    type="file"
                    multiple={false}
                    accept={'.json'}
                    onChange={handleEventJSONUpload}
                    id="event-upload"
                />
                <label htmlFor="event-upload">
                    <IconButton component="span">
                        <MdFileUpload />
                    </IconButton>
                </label>
                <IconButton onClick={handleEventJSONDownload}>
                    <MdFileDownload />
                </IconButton>
            </Grid>
            <Grid item xs={11}>
                <ParentSize>{({ width }) => <EditorPanelSVG width={width} height={400} />}</ParentSize>
            </Grid>
        </Grid>
    );
};

export default EditorPanel;
