import React from 'react';
import { EatEvent, EatEventWithId } from 'types/EatEvent';

interface IEventStreamContext {
    eatEvents: EatEventWithId[];
    setEatEvents: (events: EatEventWithId[]) => void;
    updateEatEvent: (eventId: string, eventUpdate: Partial<EatEvent>) => void;
}

const defaultContext: IEventStreamContext = {
    eatEvents: [],
    setEatEvents: () => {
        // Initial value. Replaced by context provider.
    },
    updateEatEvent: () => {
        // Initial value. Replaced by context provider.
    },
};

const EventStreamContext = React.createContext(defaultContext);

export default EventStreamContext;
