import React, { useCallback, useContext } from 'react';
import { AxisBottom } from '@visx/axis';
import { timeFormatter, videoFileFormatter } from 'lib/string-format';
import { Group } from '@visx/group';
import VideoWaveform from 'App/EditorPanel/VideoWaveform';
import OutOfRangeMarkers from 'App/EditorPanel/OutOfRangeMarkers';
import { NumberValue, ScaleLinear } from 'd3-scale';
import VideoStateContext from 'App/VideoStateContext';
import EventStreamContext from 'App/EventStreamContext';
import VideoFileContext from 'App/VideoFileContext';
import EventStream from 'App/EditorPanel/Event/EventStream';

interface Props {
    timeScale: ScaleLinear<number, number>;
    width: number;
    height: number;
    maxVideoDuration: number;
}

const EditorPanelContent: React.FunctionComponent<Props> = ({ timeScale, maxVideoDuration, width, height }: Props) => {
    const { videoFileInfos } = useContext(VideoFileContext);
    const { playbackPosition } = useContext(VideoStateContext);
    const { eatEvents } = useContext(EventStreamContext);

    const tickFormatter = useCallback((n: NumberValue) => timeFormatter(n), []);

    return (
        <>
            <AxisBottom top={0} scale={timeScale} tickFormat={tickFormatter} />
            <line
                x1={timeScale(playbackPosition) ?? 0}
                y1={0}
                x2={timeScale(playbackPosition) ?? 0}
                y2={400}
                stroke={'#123e59'}
                strokeWidth={1}
            />
            {videoFileInfos.map((videoFileInfo, idx) => (
                <Group key={videoFileInfo.id} transform={`translate(0 ${idx * 100 + 50})`} opacity={0.7}>
                    {videoFileInfo.waveform && (
                        <VideoWaveform
                            label={videoFileFormatter(videoFileInfo)}
                            timeScale={timeScale}
                            waveformData={videoFileInfo.waveform}
                            height={100}
                        />
                    )}
                </Group>
            ))}
            {
                <Group transform={`translate(0 50)`}>
                    <EventStream height={100} eventData={eatEvents} timeScale={timeScale} />
                </Group>
            }

            <OutOfRangeMarkers maxVideoDuration={maxVideoDuration} timeScale={timeScale} />
        </>
    );
};

export default EditorPanelContent;
