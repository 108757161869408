import React, { useCallback, useMemo } from 'react';
import { EatEvent, EatEventWithId } from 'types/EatEvent';
import EventStreamContext from 'App/EventStreamContext';
import produce from 'immer';

interface Props {}

const EventStreamContextProvider = ({ children }: React.PropsWithChildren<Props>) => {
    const [eatEventDict, setEatEventDict] = React.useState<Record<string, EatEvent>>({});

    const setEatEvents = useCallback((events: EatEventWithId[]) => {
        const newEatEventDict = events.reduce((acc, event) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { id: _, ...remainingEvent } = event;
            acc[event.id] = {
                ...remainingEvent,
            };
            return acc;
        }, {} as Record<string, EatEvent>);
        setEatEventDict(newEatEventDict);
    }, []);

    const updateEatEvent = useCallback((eventId: string, eventUpdate: Partial<Omit<EatEvent, 'id'>>) => {
        setEatEventDict((prevState) =>
            produce(prevState, (draftState) => {
                draftState[eventId] = {
                    ...draftState[eventId],
                    ...eventUpdate,
                };
            })
        );
    }, []);

    const eatEvents = useMemo(
        () =>
            Object.entries(eatEventDict).map(([id, event]) => {
                return {
                    ...event,
                    id,
                };
            }),
        [eatEventDict]
    );

    const providerValueMemo = useMemo(
        () => ({
            eatEvents,
            setEatEvents,
            updateEatEvent,
        }),
        [eatEvents, setEatEvents, updateEatEvent]
    );

    return <EventStreamContext.Provider value={providerValueMemo}>{children}</EventStreamContext.Provider>;
};

export default EventStreamContextProvider;
