import React, { useCallback } from 'react';
import styled from 'styled-components';
import { localPoint } from '@visx/event';

const DragHandleGroup = styled.g`
    cursor: grab;
`;

export interface DragData {
    startX: number;
    startY: number;
    endX: number;
    endY: number;
}

interface Props {
    x: number;
    y: number;
    height: number;
    width: number;
    onDrag?: (dragData: DragData) => void;
}

const DragHandle: React.FunctionComponent<Props> = ({ x, y, height, width, onDrag }: Props) => {
    const [dragStart, setDragStart] = React.useState<{ x: number; y: number } | null>(null);

    const onMouseDownHandler = useCallback((e: React.MouseEvent) => {
        const point = localPoint(e);
        setDragStart({ x: point?.x ?? 0, y: point?.y ?? 0 });
        e.preventDefault();
        e.stopPropagation();
    }, []);

    const onMouseMoveHandler = useCallback(
        (e: React.MouseEvent) => {
            if (dragStart) {
                const point = localPoint(e);

                onDrag?.({
                    startX: dragStart.x,
                    startY: dragStart.y,
                    endX: point?.x ?? 0,
                    endY: point?.y ?? 0,
                });

                setDragStart({ x: e.clientX, y: e.clientY });

                e.preventDefault();
                e.stopPropagation();
            }
        },
        [dragStart, onDrag]
    );

    const onMouseUpHandler = useCallback((e: React.MouseEvent) => {
        setDragStart(null);
        e.preventDefault();
        e.stopPropagation();
    }, []);

    const eventCatchHandler = useCallback((e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
    }, []);

    return (
        <DragHandleGroup
            onMouseDown={onMouseDownHandler}
            onMouseMove={onMouseMoveHandler}
            onMouseUp={onMouseUpHandler}
            onMouseLeave={onMouseUpHandler}
            onClick={eventCatchHandler}
            onDoubleClick={eventCatchHandler}
        >
            {dragStart !== null && (
                // If we are currently dragging, insert a transparent rectangle to ensure
                // that the mouse events are captured by the parent group.
                <rect
                    x={x - 1000}
                    y={y - 1000}
                    width={width + 2000}
                    height={height + 2000}
                    strokeOpacity={0}
                    fillOpacity={0}
                />
            )}
            {/* Add transparent event catcher rectangle, so that it is easier to hit the group. */}
            <rect x={x - 6} y={y - 6} width={width + 12} height={height + 12} strokeOpacity={0} fillOpacity={0} />
            {/* Insert the dragging symbol. */}
            <line x1={x} y1={y} x2={x} y2={y + height} stroke={'white'} />
            <line x1={x + width} y1={y} x2={x + width} y2={y + height} stroke={'white'} />
        </DragHandleGroup>
    );
};

export default DragHandle;
