import React, { useContext } from 'react';
import FileManager from 'App/FileManager/FileManager';
import VideoFileContextProvider from 'App/VideoFileContextProvider';
import VideoPlayer from 'App/VideoPlayer/VideoPlayer';
import { AppBar, Container, Grid, IconButton, makeStyles, Paper, Toolbar, Typography } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import VideoStateContextProvider from 'App/VideoStateContextProvider';
import EditorPanel from 'App/EditorPanel/EditorPanel';
import { useKey } from 'react-use';
import VideoStateContext from 'App/VideoStateContext';
import VideoSeekContext from 'App/VideoSeekContext';
import VideoSeekContextProvider from 'App/VideoSeekContextProvider';
import EventStreamContextProvider from 'App/EventStreamContextProvider';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    mainContainer: {
        padding: theme.spacing(2),
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
}));

function App() {
    const classes = useStyles();

    return (
        <>
            <AppBar position="static">
                <Toolbar>
                    <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        DBVIS Collective Eating Event Editor
                    </Typography>
                </Toolbar>
            </AppBar>
            <VideoFileContextProvider>
                <VideoStateContextProvider>
                    <VideoSeekContextProvider>
                        <AppContent />
                    </VideoSeekContextProvider>
                </VideoStateContextProvider>
            </VideoFileContextProvider>
        </>
    );
}

const AppContent: React.FunctionComponent = () => {
    const { toggleIsPlaying } = useContext(VideoStateContext);
    const { seekDelta } = useContext(VideoSeekContext);
    const classes = useStyles();

    useKey('ArrowRight', (e: KeyboardEvent) => {
        const increment = e.shiftKey ? 1 : 0.1;
        seekDelta(increment);
    });
    useKey('ArrowLeft', (e: KeyboardEvent) => {
        const increment = e.shiftKey ? -1 : -0.1;
        seekDelta(increment);
    });
    useKey(' ', (e: KeyboardEvent) => {
        toggleIsPlaying();
    });

    return (
        <Container className={classes.mainContainer} maxWidth={'xl'}>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <Paper className={classes.paper}>
                        <FileManager />
                    </Paper>
                </Grid>
                <Grid item xs={8}>
                    <Paper className={classes.paper}>
                        <VideoPlayer />
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <EventStreamContextProvider>
                            <EditorPanel />
                        </EventStreamContextProvider>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
};

export default App;
