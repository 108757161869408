import React from 'react';
import { ScaleLinear } from 'd3-scale';
import { Group } from '@visx/group';
import { EatEventWithId } from 'types/EatEvent';
import EventComponent from 'App/EditorPanel/Event/EventComponent';

interface Props {
    height: number;
    timeScale: ScaleLinear<number, number>;
    eventData: EatEventWithId[];
}

const EventStream: React.FunctionComponent<Props> = ({ timeScale, eventData, height }: Props) => {
    const offset = height / 10;
    const rectHeight = height / 5;

    return (
        <Group id={'eventStream'} transform={`translate(0 ${height / 10})`}>
            {eventData.map((eatEvent) => (
                <EventComponent
                    key={eatEvent.id}
                    eatEvent={eatEvent}
                    timeScale={timeScale}
                    y={(parseInt(eatEvent.person) - 1) * (rectHeight + offset)}
                    height={20}
                />
            ))}
        </Group>
    );
};

export default EventStream;
