import React, { useCallback, useMemo } from 'react';
import VideoFileContext from 'App/VideoFileContext';
import produce from 'immer';
import { v4 as uuidv4 } from 'uuid';
import { VideoFileInfo, VideoFileInfoWithId } from 'types/VideoFileInfo';

interface Props {}

const VideoFileContextProvider = ({ children }: React.PropsWithChildren<Props>) => {
    const [videoFileInfoDict, setVideoFileInfoDict] = React.useState<Record<string, VideoFileInfo>>({});

    const addVideoFileInfo = useCallback((file: VideoFileInfo): string => {
        const id = uuidv4();
        setVideoFileInfoDict((prevState) =>
            produce(prevState, (draftState) => {
                draftState[id] = file;
            })
        );
        return id;
    }, []);

    const updateVideoFileInfo = useCallback((videoFileId: string, updates: Partial<VideoFileInfo>) => {
        setVideoFileInfoDict((prevState) =>
            produce(prevState, (draftState) => {
                draftState[videoFileId] = {
                    ...prevState[videoFileId],
                    ...updates,
                };
            })
        );
    }, []);

    const deleteVideoFileInfo = useCallback((videoFileId: string) => {
        setVideoFileInfoDict((prevState) =>
            produce(prevState, (draftState) => {
                delete draftState[videoFileId];
            })
        );
    }, []);

    const videoFileInfos = useMemo(
        (): VideoFileInfoWithId[] =>
            Object.entries(videoFileInfoDict).map(([id, info]) => {
                return {
                    id,
                    ...info,
                };
            }),
        [videoFileInfoDict]
    );

    // Memoize the value object itself, so it doesn't lead to unnecessary re-renders.
    const providerValueMemo = useMemo(
        () => ({
            videoFileInfos,
            addVideoFileInfo,
            updateVideoFileInfo,
            deleteVideoFileInfo,
        }),
        [videoFileInfos, addVideoFileInfo, updateVideoFileInfo, deleteVideoFileInfo]
    );

    return <VideoFileContext.Provider value={providerValueMemo}>{children}</VideoFileContext.Provider>;
};

export default VideoFileContextProvider;
