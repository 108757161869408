import React, { useContext } from 'react';
import VideoFileContext from 'App/VideoFileContext';
import VideoPlayerInstance from 'App/VideoPlayer/VideoPlayerInstance';
import VideoControls from 'App/VideoPlayer/VideoControls';
import { Grid } from '@material-ui/core';
import { VideoFileInfoWithId } from 'types/VideoFileInfo';

interface Props {}

const VideoPlayer: React.FunctionComponent<Props> = ({}: Props) => {
    const { videoFileInfos } = useContext(VideoFileContext);

    const longestVideo = videoFileInfos.reduce(
        (longestVideo: null | VideoFileInfoWithId, videoFileInfo: VideoFileInfoWithId) => {
            if (longestVideo === null || videoFileInfo.duration > longestVideo.duration) {
                return videoFileInfo;
            }
            return longestVideo;
        },
        null
    );

    return (
        <Grid container spacing={2}>
            {videoFileInfos.map((videoFileInfo) => (
                <Grid key={videoFileInfo.id} item xs={4}>
                    <VideoPlayerInstance
                        isControllingInstance={longestVideo?.id === videoFileInfo.id}
                        videoFileInfo={videoFileInfo}
                        key={videoFileInfo.id}
                    />
                </Grid>
            ))}
            <Grid item xs={12}>
                <VideoControls />
            </Grid>
        </Grid>
    );
};

export default VideoPlayer;
