import { scaleLinear } from '@visx/scale';
import React, { memo, useMemo, useState } from 'react';
import { Area } from '@visx/shape';
import WaveformData from 'waveform-data';
import _ from 'lodash';
import { ScaleLinear } from 'd3-scale';
import { Group } from '@visx/group';
import { Text } from '@visx/text';

interface Props {
    height: number;
    timeScale: ScaleLinear<number, number>;
    waveformData: WaveformData;
    label?: string;
}

const VideoWaveform: React.FunctionComponent<Props> = ({ timeScale, waveformData, height, label }: Props) => {
    const timeDomain = useMemo(() => timeScale.domain(), [timeScale]);
    const screenDomain = useMemo(() => timeScale.range(), [timeScale]);
    const currentInterval = timeDomain[1] - timeDomain[0];

    return (
        <Group transform={`translate(${timeScale(0)} 0)`}>
            <Group transform={`scale(${(waveformData.duration / currentInterval) * screenDomain[1]} 1.0)`}>
                <MemoizedVideoWaveformContent waveformData={waveformData} height={height} width={1} />
            </Group>
            {label && <VideoWaveformLabel x={10} y={height / 2} label={label} />}
        </Group>
    );
};

interface LabelProps {
    x: number;
    y: number;
    label: string;
    padding?: number;
}

const VideoWaveformLabel: React.FunctionComponent<LabelProps> = ({ x, y, label, padding = 3 }: LabelProps) => {
    const [textRef, setTextRef] = useState<SVGTextElement | null>();

    const textBBox = textRef?.getBBox();

    return (
        <>
            {textBBox && (
                <rect
                    x={textBBox.x - padding}
                    y={textBBox.y - padding}
                    width={textBBox.width + padding * 2}
                    height={textBBox.height + padding * 2}
                    fill={'rgba(255, 255, 255, 0.5)'}
                    rx={5}
                />
            )}
            <Text
                innerTextRef={(r) => setTextRef(r)}
                x={x + padding}
                y={y}
                dominantBaseline={'middle'}
                verticalAnchor={'middle'}
                textAnchor={'start'}
                className={'no-pointer'}
            >
                {label}
            </Text>
        </>
    );
};

interface ContentProps {
    width: number;
    height: number;
    waveformData: WaveformData;
}

const VideoWaveformContent: React.FunctionComponent<ContentProps> = ({ waveformData, height, width }: ContentProps) => {
    const chMin = useMemo(() => waveformData.channel(0).min_array(), [waveformData]);
    const chMax = useMemo(() => waveformData.channel(0).max_array(), [waveformData]);

    const x = scaleLinear<number>().domain([0, waveformData.length]).range([0, width]);

    const y = scaleLinear<number>()
        .domain([Math.min(...chMin), Math.max(...chMax)])
        .rangeRound([0, height]);

    const data = useMemo(() => _.zip(chMin, chMax) as [number, number][], [chMax, chMin]);

    return (
        <>
            <Area<[number, number]>
                fill={'#1a4057'}
                data={data}
                x={(d, i) => x(i)}
                y0={(d) => y(d[0])}
                y1={(d) => y(d[1])}
            />
        </>
    );
};

const MemoizedVideoWaveformContent = memo(VideoWaveformContent);

export default VideoWaveform;
