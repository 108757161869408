import React, { useCallback, useMemo } from 'react';
import VideoSeekContext from 'App/VideoSeekContext';

interface Props {}

const VideoSeekContextProvider = ({ children }: React.PropsWithChildren<Props>) => {
    const [seekPosition, setSeekPosition] = React.useState<number>(0);

    const seekDelta = useCallback((increment: number) => {
        setSeekPosition((prevPosition) => prevPosition + increment);
    }, []);

    // Memoize the value object itself, so it doesn't lead to unnecessary re-renders.
    const providerValueMemo = useMemo(
        () => ({
            seekPosition,
            seekTo: setSeekPosition,
            seekDelta,
        }),
        [seekDelta, seekPosition]
    );

    return <VideoSeekContext.Provider value={providerValueMemo}>{children}</VideoSeekContext.Provider>;
};

export default VideoSeekContextProvider;
