/* eslint-disable @typescript-eslint/no-unused-vars */
import { err, JsonDecoder, ok } from 'ts.data.json';
import { $JsonDecoderErrors } from 'ts.data.json/dist/json-decoder';
import { EatEventWithId } from 'types/EatEvent';
import { v4 as uuidv4 } from 'uuid';

/** **********************
 * Useful Array Decoders *
 *********************** */
export const stringArrayDecoder = JsonDecoder.array<string>(JsonDecoder.string, 'string[]');

export const stringOrNumberDecoder = JsonDecoder.oneOf<string | number>(
    [JsonDecoder.string, JsonDecoder.number],
    'string | number'
);

export const stringOrNumberArrayDecoder = JsonDecoder.array<string | number>(
    stringOrNumberDecoder,
    'Array<string|number>[]'
);

/** *****
 * Date *
 ****** */
const dateDecoder: JsonDecoder.Decoder<Date> = new JsonDecoder.Decoder<Date>((json: unknown) => {
    if (typeof json === 'string') {
        const isValidDate = !isNaN(Date.parse(json));

        if (isValidDate) {
            return ok<Date>(new Date(json));
        }
    }

    return err<Date>($JsonDecoderErrors.primitiveError(json, 'Date'));
});

/** **
 * UUID
 */
const uuidFailoverDecoder: JsonDecoder.Decoder<string> = new JsonDecoder.Decoder<string>((json: unknown) => {
    if (json === undefined) {
        return ok<string>(uuidv4());
    }

    if (typeof json === 'string') {
        // Verify that json string is a valid UUID.
        const isUuid = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(json);

        if (isUuid) {
            return ok<string>(json);
        }
    }

    return err<string>($JsonDecoderErrors.primitiveError(json, 'UUID'));
});

/** ****************
 * Interestingness *
 ***************** */

const eatEventDecoder = JsonDecoder.object<EatEventWithId>(
    {
        id: uuidFailoverDecoder,
        person: JsonDecoder.string,
        hand: JsonDecoder.string,
        time_start: JsonDecoder.number,
        time_end: JsonDecoder.number,
    },
    'EatEvent'
);

export const eatEventListDecoder = JsonDecoder.array<EatEventWithId>(eatEventDecoder, 'EatEvent[]');
