import WaveformData from 'waveform-data';
import { VideoFileInfo } from 'types/VideoFileInfo';

export function getVideoDuration(file: File): Promise<number> {
    return new Promise((resolve, reject) => {
        const video = document.createElement('video');
        video.preload = 'metadata';
        video.onloadedmetadata = function () {
            window.URL.revokeObjectURL(video.src);
            resolve(video.duration);
        };
        video.src = URL.createObjectURL(file);
    });
}

export function getAudioWaveformData(file: File): Promise<WaveformData> {
    const audioContext = new AudioContext();
    const objectURL = URL.createObjectURL(file);

    return fetch(objectURL)
        .then((response) => {
            window.URL.revokeObjectURL(objectURL);
            return response.arrayBuffer();
        })
        .then((buffer): Promise<WaveformData> => {
            const options = {
                audio_context: audioContext,
                array_buffer: buffer,
                scale: 2048,
            };

            return new Promise((resolve, reject) => {
                WaveformData.createFromAudio(options, (err, waveform) => {
                    if (err) {
                        reject(err);
                    } else {
                        resolve(waveform);
                    }
                });
            });
        });
}

export async function prepareVideoFileInfo(file: File): Promise<VideoFileInfo> {
    const duration = await getVideoDuration(file);
    return {
        name: file.name,
        objectURL: URL.createObjectURL(file),
        duration,
    };
}
