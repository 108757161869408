import React, { useCallback, useContext } from 'react';
import VideoFileContext from 'App/VideoFileContext';
import { IconButton } from '@material-ui/core';
import { MdOutlineDeleteForever } from 'react-icons/md';

interface Props {
    fileId: string;
}

const FileDeleteButton: React.FunctionComponent<Props> = ({ fileId }: Props) => {
    const { deleteVideoFileInfo } = useContext(VideoFileContext);

    const handleButtonClick = useCallback(() => deleteVideoFileInfo(fileId), [deleteVideoFileInfo, fileId]);

    return (
        <IconButton onClick={handleButtonClick} size="medium">
            <MdOutlineDeleteForever />
        </IconButton>
    );
};

export default FileDeleteButton;
